<template>
    <div style="height: 100%; overflow: auto;" >
        <div class="user-card md-elevation-4">
            <div class="user-card-avatar">
                <md-avatar class="md-avatar-icon">{{avatar(nombre)}}</md-avatar>
            </div>
            <div class="user-card-content" style="padding: 10px">
                <div class="md-title" style="">{{nombre}}</div>
                <div class="md-subhead">
                    <md-icon>flag</md-icon> {{lang.reserva}}: # {{$route.params.id}}
                </div>
                <div class="md-subhead">
					<md-icon>king_bed</md-icon> <strong>{{booking.categoria}}</strong> | {{booking.habitacion}}
					<div style="text-align: right; margin-top: 10px">
						<md-chip>
							<span style="display: flex; align-items: center">
								<md-icon>info</md-icon> <span style="margin-left: 3px"> {{status}} </span>
							</span>
						</md-chip>
					</div>
				</div>
            </div>
        </div>
        <md-list class="md-double-line">
            <md-subheader>
                {{lang.titular}}
            </md-subheader>
            <md-list-item>
                <md-icon class="md-primary">email</md-icon>
                <div class="md-list-item-text">
                    <span v-if="booking.titular.email">{{booking.titular.email}}</span>
                    <span v-else>{{lang.sin_asignar}}</span>
                    <span>{{lang.email}}</span>
                </div>

            </md-list-item>

            <md-list-item >
                <md-icon class="md-primary">phone</md-icon>
                <div class="md-list-item-text">
                    <span v-if="booking.titular.telefono"> {{booking.titular.telefono}}</span>
                    <span v-else>{{lang.sin_asignar}}</span>
                    <span>{{lang.telefono}}</span>
                </div>

            </md-list-item>
			<md-divider></md-divider>
            <md-subheader>
                {{lang.reserva}}
            </md-subheader>
            <md-list-item>
                <md-icon class="md-primary">flight_land</md-icon>
                <div class="md-list-item-text">
                    <span>{{booking.fecha_ingreso}}</span>
                    <span>Check in</span>
                </div>

            </md-list-item>

            <md-list-item>
                <md-icon class="md-primary">flight_takeoff</md-icon>
                <div class="md-list-item-text">
                    <span>{{booking.fecha_salida}}</span>
                    <span>Check out</span>
                </div>
            </md-list-item>

            <md-list-item>
                <md-icon class="md-primary">nights_stay</md-icon>
                <div class="md-list-item-text">
                    <span>{{booking.noches}}</span>
                    <span>{{lang.noches}}</span>
                </div>
            </md-list-item>

            <md-list-item v-if="false">
                <md-icon class="md-primary">king_bed</md-icon>
                <div class="md-list-item-text">
                    <span>{{booking.numero_reservas_grupo}}</span>
                    <span v-if="booking.numero_reservas_grupo < 2">{{lang.habitacion}}</span>
                    <span v-else>{{lang.habitaciones}}</span>
                </div>
            </md-list-item>

            <md-list-item>
                <md-icon class="md-primary">person</md-icon>
                <div class="md-list-item-text">
                    <span>{{booking.adultos}}</span>
                    <span >{{lang.adultos}}</span>
                </div>
            </md-list-item>

            <md-list-item v-if="booking.menores > 0">
                <md-icon class="md-primary">child_friendly</md-icon>
                <div class="md-list-item-text">
                    <span>{{booking.menores}}</span>
                    <span >{{lang.menores}}</span>
                </div>
            </md-list-item>

			<md-list-item >
				<md-icon class="md-primary">contactless</md-icon>
				<div class="md-list-item-text">
					<span>{{booking.agencia}}</span>
					<span >{{lang.canal}}</span>
				</div>
			</md-list-item>


			<md-divider></md-divider>
			<md-subheader>
				{{lang.notas}}
			</md-subheader>
			<div style="padding: 0px 20px 80px">
				{{booking.notas}}
			</div>

        </md-list>

        <md-speed-dial
                class="md-bottom-right"
                md-effect="scale"
                mdEvent="click"
                v-if="booking.titular.email && booking.status != 'entransaccion' && booking.permisocheckin === 1"
        >
            <md-speed-dial-target class="md-primary">
                <md-icon class="md-morph-initial">send</md-icon>
                <md-icon class="md-morph-final">close</md-icon>
            </md-speed-dial-target>

            <md-speed-dial-content>
                <md-button
                        class="md-dense"
                        @click="notificacion"
                        v-if="booking.status != 'entransaccion'">
                    {{lang.notificacion}} <md-icon>markunread</md-icon>
                 </md-button>
                <md-button
                        class="md-dense"
                        @click = "check_in_online"
                        v-if="booking.permisocheckin === 1">
                    {{lang.checkin_online}}<md-icon>how_to_reg</md-icon>
                 </md-button>
            </md-speed-dial-content>
        </md-speed-dial>
    </div>
</template>
<script>
	import {empty, request, toFormData, Utility, avatar} from '../../../helpers/';
export default {
    computed:{
        lang(){return this.$store.state.lang},
		booking() {

        	let booking = this.$store.state.booking;
        	let data ={
        		titular :{
					email: this.lang.sin_asignar,
					telefono: this.lang.sin_asignar
				},
				detallesBasicos :{}
			};

        	if(!empty(booking)){
                data.titular         = booking.titular[0];
                data.detallesBasicos = booking.detallesBasicos[0];
                data.fecha_ingreso   = data.detallesBasicos.fecha_ingreso;
                data.fecha_salida    = data.detallesBasicos.fecha_salida;
                data.categoria       = data.detallesBasicos.nombreCategoria;
                data.habitacion      = data.detallesBasicos.nombreCuarto;
                data.noches          = booking.cantidad_noches;
                data.adultos         = (data.detallesBasicos.numero_personas - data.detallesBasicos.numero_menores);
                data.menores         = data.detallesBasicos.numero_menores;
                data.notas           = data.detallesBasicos.nota;
                data.agencia         = (data.detallesBasicos.nombre_agencia) ? data.detallesBasicos.nombre_agencia : this.lang.sin_asignar
                data.permisocheckin  = booking.permisocheckin;
                data.status          = booking.detallesBasicos[0].estatus;
			}

        	return  data
		},
		nombre(){
        	if(!empty(this.$store.state.booking.titular)){
				let titular = this.$store.state.booking.titular[0];
				let nombre = (titular.nombre)??'';
				let primer_apellido = (titular.primer_apellido)??'';
				let segundo_apellido =(titular.segundo_apellido)??'';
				return nombre + ' '+ primer_apellido + ' '+ segundo_apellido
			}
        	return '';
		},
		status(){
        	let $spanText ='';
			if(!empty(this.booking.detallesBasicos)){
				if (this.booking.detallesBasicos.estatus == 'provisional' && this.booking.detallesBasicos.distribuida == 1){
					$spanText = "Distribuida";
				} else if (this.booking.detallesBasicos.confirmada != 1) {
					$spanText = "Provisional";
				} else {
					switch (this.booking.detallesBasicos.estatus) {
					case "provisional":
						$spanText = this.lang.sin_pago;
						break;
					case "parcial":
						$spanText = this.lang.pago_parcial;
						break;
					case "completo":
						$spanText = this.lang.pago_comple;
						break;
					case "fin":
						$spanText = this.lang.hospeda_finaliza;
						break;
					case "entransaccion":
						$spanText = this.lang.transaccion;
						break;
					case "traslado":
						$spanText = this.lang.traslado_habitacion;
						break;
					}
				}
			}
        	return $spanText;
		}
    },
    name:'TabDetalle',
	methods: {

    	async notificacion (){
    	    this.$store.state.loading = true;
			let r = await request(base_url+"/correoCliente?view=json&jwt="+this.$store.state.jwt, toFormData( {'idRes': this.$route.params.id}));
			if(r.ok) {
				if(r.r.data) {
					let idEmailTemplate = '';
					let info = r.r.data;
					let id_agente = info[0].id_agente;
					let id_reserva = info[0].id_reserva;
					let identificacion = info[0].identificacion;
					let nombreCliente = info[0].nombreCliente;
					let primer_apellido = info[0].primer_apellido;
					let segundo_apellido = info[0].segundo_apellido;
					let nombrePais = info[0].nombrePais;
					let telefono = info[0].telefono;
					let email = info[0].email;
					let moneda = info[0].moneda;
					let nombreCuarto = info[0].nombreCuarto;
					let nombreCategoria = info[0].nombreCategoria;
					let fecha_ingreso = info[0].fecha_ingreso;
					let fecha_salida = info[0].fecha_salida;
					let numero_personas = info[0].numero_personas;
					let nota = info[0].nota;
					let total_a_pagar = info[0].total_a_pagar;
					let politica_cancelacion = info[0].politica_cancelacion;
					let descripcion_impuestos_cargos = info[0].descripcion_impuestos_cargos;
					let terminos_condiciones = info[0].terminos_condiciones;
					let check_in = info[0].check_in;
					let check_out = info[0].check_out;
					let nombre_establecimiento = info[0].nombre_establecimiento;
					let direccion = info[0].direccion;
					let ciudad = info[0].ciudad;
					let nombre = info[0].nombre;
					let telefonoHotel = info[0].telefonoHotel;
					let emailHotel = info[0].emailHotel;
					let pagina_web = info[0].pagina_web;
					let link_facebook = info[0].link_facebook;
					let pais_cliente = info[0].pais_cliente;
					let pendiente = info[0].pendiente;
					let politicas = info[0].politicas;
					let delete_auto_date = info[0].delete_date;
					let confirmada_d = info[0].confirmada;

					let form =  {
						id_reserva                  : empty(id_reserva)?'': id_reserva,
						idEmailTemplate             : empty(idEmailTemplate)?'': idEmailTemplate,
						id_agente                   : empty(id_agente)?'': id_agente,
						politicas                   : empty(politicas)?[]: politicas,
						moneda                      : empty(moneda)?'': moneda,
						link_facebook               : empty(link_facebook)?'': link_facebook,
						politica_cancelacion        : empty(politica_cancelacion)? '': politica_cancelacion,
						descripcion_impuestos_cargos: empty(descripcion_impuestos_cargos)?'': descripcion_impuestos_cargos,
						check_in                    : empty(check_in)?'': check_in,
						check_out                   : empty(check_out)?'': check_out,
						nombre_establecimiento      : empty(nombre_establecimiento)?'': nombre_establecimiento,
						direccion                   : empty(direccion)?'': direccion,
						ciudad                      : empty(ciudad)?'': ciudad,
						nombre                      : empty(nombre)?'': nombre,
						telefonoHotel               : empty(telefonoHotel)?'': telefonoHotel,
						emailHotel                  : empty(emailHotel)?'': emailHotel,
						pagina_web                  : empty(pagina_web)?'': pagina_web,
						total_a_pagar               : empty(total_a_pagar)? 0 : total_a_pagar,
						nota                        : empty(nota)?'': nota,
						numero_personas             : empty(numero_personas)?'': numero_personas,
						fecha_salida                : empty(fecha_salida)?'': fecha_salida,
						fecha_ingreso               : empty(fecha_ingreso)?'': fecha_ingreso,
						nombreCategoria             : empty(nombreCategoria)?'': nombreCategoria,
						nombreCuarto                : empty(nombreCuarto)?'': nombreCuarto,
						email                       : empty(email)?'': email,
						telefono                    : empty(telefono)?'': telefono,
						nombrePais                  : empty(nombrePais)?'': nombrePais,
						id                          : empty(identificacion)?'': identificacion,
						nombreCliente               : empty(nombreCliente)?'': nombreCliente,
						primer_apellido             : empty(primer_apellido)?'': primer_apellido,
						segundo_apellido            : empty(segundo_apellido)?'': segundo_apellido,
						pais_cliente                : empty(pais_cliente)? '' : pais_cliente,
						pendiente                   : empty(pendiente)? 0 : pendiente,
						terminos_condiciones        : empty(terminos_condiciones)?'': terminos_condiciones,
						delete_auto                 : empty(delete_auto_date)?'': delete_auto_date,
						confirmada                  : empty(confirmada_d)?'': confirmada_d
					};

					let r2  = await request(base_url+'/enviarCorreoUsuario?view=json&jwt='+this.$store.state.jwt, Utility.convertModelToFormData(form));
					if(r2.ok){
					    this.$store.commit('setSnackBar', {
					        show : true, text: r2.r.data.message
                        })

                    }
				}

				this.$store.state.loading = false;
			}

			return  false;
		},
    	async check_in_online(){
            this.$store.state.loading = true;
			let r  = await request(base_url+'/correoCliente/checkin?jwt='+this.$store.state.jwt, toFormData( {'idRes': this.$route.params.id}));
			if(r.ok){
                this.$store.commit('setSnackBar', {
                    show : true, text: this.lang.email_enviado
                })
            }
			this.$store.state.loading = false;
		},
    	avatar(name) {
			name = name.toUpperCase().trim();
			var iniciales = 'H';
			var nombre = name.split(' ');
			if (nombre.length == 1) {
				iniciales = nombre[0].charAt(0) + nombre[0].charAt(1);
			}

			if (nombre.length == 2 || nombre.length == 3) {
				iniciales = nombre[0].charAt(0) + nombre[1].charAt(0);
			}

			if (nombre.length > 3) {
				iniciales = nombre[0].charAt(0) + nombre[2].charAt(0);
			}

			return iniciales
		},
	},
    data(){
        return{


        }
    },
}
</script>
